import store from "@/store";
import { Actions } from "@/store/enums/SmsStoreEnums";
import { ProfileDetails } from "@/types/StudentModel";

export const setStudentProfile = (
  studentProfile: any,
): void => {
  store.dispatch(Actions.SET_STUDENTPROFILE_ACTION, {
    studentProfile: studentProfile,
  });
};
